// 新增/编辑 招聘岗位
<template>

  <div class="popAddJob">
    <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogVisible" width="600px" :before-close="close">
      <el-form :model="form" ref="form" :rules="formRules" label-width="80px" label-position="top" :inline="false">
        <div class="popItemBox">
          <div class="title">岗位信息</div>
          <div class="p20">
            <el-row>
              <el-col :span="12">
                <el-form-item label="岗位名称：" class="fll mr15" prop="name">
                  <el-input v-model="form.name" placeholder="请输入岗位名称:"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="岗位发布日期：" class="fll mr15" prop="postDate">
                  <el-date-picker v-model="form.postDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择岗位发布日期：">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="薪资范围（k）：" class="fll mr15" prop="salaryLow">
                  <el-col :span="12">
                    <el-form-item class="mr15" prop="salaryLow">
                      <el-input v-model="form.salaryLow"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item class="mr15" prop="salaryHigh">
                      <el-input v-model="form.salaryHigh"></el-input>
                    </el-form-item>
                  </el-col>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="薪酬数（月）：" class="fll mr15" prop="salaryMonths">
                  <el-input v-model="form.salaryMonths" placeholder="请输入薪酬数（月）："></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="工作地点：" class="fll mr15" prop="provinceCity">
                  <el-cascader v-model="form.provinceCity" :options="options" @change="handleChange"></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="要求工作年限：" class="fll mr15" prop="yearsLow">
                  <el-col :span="12">
                    <el-form-item class="mr15" prop="yearsLow">
                      <el-input v-model="form.yearsLow"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item class="mr15" prop="yearsHigh">
                      <el-input v-model="form.yearsHigh"></el-input>
                    </el-form-item>
                  </el-col>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="学历要求：" class="fll mr15" prop="education">
                  <el-select v-model="form.education" placeholder="请选择学历要求：" clearable>
                    <el-option v-for="item in educationList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="popItemBox">
          <div class="title">岗位信息</div>
          <div class="p20">
            <el-row>
              <el-col :span="24">
                <div class="describe flex-between-center">
                  <el-col :span="6">
                    <div class="left">招聘网站名称</div>
                  </el-col>
                  <el-col :span="16">
                    <div class="center">岗位对应网址</div>
                  </el-col>
                  <el-col :span="2">
                    <div class="right"></div>
                  </el-col>
                </div>
                <div class="websites-box"></div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item v-for="(item,index) in form.websites" :key="item.id">
                  <el-col :span="6">
                    <el-form-item class="fll mr15" :prop="'websites.' + index + '.websiteName'" :rules="{required: true, message: '网站名称不能为空', trigger: 'blur'}">
                      <el-input v-model="item.websiteName" placeholder="请输入招聘网站名称"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="16">
                    <el-form-item class="fll mr15" :prop="'websites.' + index + '.websiteUrl'" :rules="{required: true, message: '招聘岗位对应网址不能为空', trigger: 'blur'}" style="width:100%;">
                      <el-input v-model="item.websiteUrl" placeholder="请输入岗位对应网址"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="2">
                    <i @click.prevent="removeWebsites(index)" style="margin: 0 15px;cursor: pointer;" class="el-icon-error"></i>
                  </el-col>

                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-button type="primary" @click="addWebsites" style="width:100%">添加招聘信息</el-button>

              </el-col>
            </el-row>
          </div>
        </div>

        <div class="footer">
          <el-button type="danger" @click="close">取消</el-button>
          <el-button type="primary" @click="save" v-loading="btnLoading">确定</el-button>
        </div>
      </el-form>

    </el-dialog>

  </div>
</template>

<script>
import API from "@/api/homeConfigManage.js";
import area from "@/utils/area.js";
export default {
  name: "popAddJob",
  components: {},

  data() {
    let that = this;
    // 自定义rules
    var checkSalaryHigh = (rule, value, callback) => {
      if (!value) {
        callback();
      }
      if (that.form.salaryLow && +value < +that.form.salaryLow) {
        callback(new Error("薪资范围最低薪必须小于最高薪"));
      } else {
        callback();
      }
    };
    var checkYearsLow = (rule, value, callback) => {
      if (!value) {
        callback();
      }
      if (+value > 30 || +value < 1) {
        callback(new Error("工作年限在1-30年"));
      } else {
        callback();
      }
    };
    var checkYearsHigh = (rule, value, callback) => {
      if (!value) {
        callback();
      }
      if (that.form.yearsLow && +value < +that.form.yearsLow) {
        callback(new Error("工作年限在1-30年"));
      }
      if (+value > 30 || +value < 1) {
        callback(new Error("工作年限在1-30年"));
      } else {
        callback();
      }
    };
    return {
      title: "新增招聘岗位",
      dialogVisible: false,
      btnLoading: false,
      // 级联相关
      value: [],
      options: area,
      educationList: [
        /* {
          label: "不限",
          value: "不限",
        },
        {
          label: "初中及以下",
          value: "初中及以下",
        },
        {
          label: "中专/中技",
          value: "中专/中技",
        },
        {
          label: "高中",
          value: "高中",
        }, */
        {
          label: "专科",
          value: "专科",
        },
        {
          label: "本科",
          value: "本科",
        },
        {
          label: "硕士",
          value: "硕士",
        },
        {
          label: "博士",
          value: "博士",
        },
      ],
      id: "",
      form: {
        type: "", //类型，枚举 【OFFICIAL('正式'),INTERNSHIP('实习')】
        name: "", //岗位名称
        postDate: new Date(), //发布日期
        salaryLow: "", //最低薪资
        salaryHigh: "", //最高薪资
        salaryMonths: "",
        websites: [],
        provinceCity: "", //工作地点（省市）
        // city: "", //工作地点（市）
        // province: "", //工作地点（省）
        yearsLow: "",
        yearsHigh: "",
        education: "",
      },
      formRules: {
        name: [
          {
            required: true,
            message: "请输入岗位名称",
            trigger: ["blur", "change"],
          },
        ],
        postDate: [
          {
            required: true,
            message: "请选择发布日期",
            trigger: ["blur", "change"],
          },
        ],
        salaryLow: [
          {
            pattern: /^[1-9]\d*$/, //使用正则 非零的正整数：
            message: "请输入正整数",
            trigger: ["blur", "change"],
          },
        ],
        salaryHigh: [
          {
            pattern: /^[1-9]\d*$/, //使用正则 非零的正整数：
            message: "请输入正整数",
            trigger: ["blur", "change"],
          },
          { validator: checkSalaryHigh, trigger: ["blur", "change"] }, //使用自定义验证器validator
        ],
        salaryMonths: [
          {
            pattern: /^[1-9]\d*$/, //使用正则 非零的正整数：
            message: "请输入正整数",
            trigger: ["blur", "change"],
          },
        ],
        yearsLow: [
          {
            pattern: /^[1-9]\d*$/, //使用正则 非零的正整数：
            message: "请输入正整数",
            trigger: ["blur", "change"],
          },
          { validator: checkYearsLow, trigger: ["blur", "change"] }, //使用自定义验证器validator
        ],
        yearsHigh: [
          {
            pattern: /^[1-9]\d*$/, //使用正则 非零的正整数：
            message: "请输入正整数",
            trigger: ["blur", "change"],
          },
          { validator: checkYearsHigh, trigger: ["blur", "change"] }, //使用自定义验证器validator
        ],
      },
    };
  },
  methods: {
    // 关闭弹框
    close() {
      this.clearForm();
      this.dialogVisible = false;
    },
    // 获取官网招聘信息详情
    initForm(id) {
      // 调用接口
      let queryList = {
        id: id,
      };
      API.detailJob(queryList)
        .then((res) => {
          this.form = res.content;
          this.form.provinceCity = this.form.province
            ? [this.form.province, this.form.city]
            : [this.form.city];
          this.form.type = this.form.type.name;
        })
        .catch(() => {});
    },
    clearForm() {
      this.form = {
        type: "", //类型，枚举 【OFFICIAL('正式'),INTERNSHIP('实习')】
        name: "", //岗位名称
        postDate: new Date(), //发布日期
        salaryLow: "", //最低薪资
        salaryHigh: "", //最高薪资
        salaryMonths: "",
        websites: [],
        provinceCity: "", //工作地点（省市）
        // city: "", //工作地点（市）
        // province: "", //工作地点（省）
        yearsLow: "",
        yearsHigh: "",
        education: "",
      };
      this.$nextTick(function () {
        this.$refs["form"].clearValidate();
      });
    },
    handleChange(value) {
      console.log(value);
    },
    // 增加招聘网站
    addWebsites() {
      this.form.websites.push({
        id: "",
        version: "",
        websiteName: "",
        websiteUrl: "",
      });
    },
    // 删除招聘网站
    removeWebsites(index) {
      this.form.websites.splice(index, 1);
    },
    // 保存
    save() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let data = Object.assign({}, this.form, {
            // id: this.id,city,province
            city:
              this.form.provinceCity.length > 1
                ? this.form.provinceCity[1]
                : this.form.provinceCity[0],
            province:
              this.form.provinceCity.length > 1
                ? this.form.provinceCity[0]
                : "",
          });
          delete data.provinceCity;
          this.btnLoading = true;
          API.saveJob(data)
            .then(() => {
              this.btnLoading = false;
              this.$emit("update");
              this.close();
            })
            .catch(() => {
              this.btnLoading = false;
            });
        } else {
          this.$message.error("请检查标星内容是否填写");
          return false;
        }
      });
    },
  },

  computed: {},

  watch: {},
};
</script>

<style lang="scss" scoped>
.popAddJob {
  .describe {
    margin-bottom: 10px;
    .left,
    .center {
      background: #e8f2fa;
      line-height: 36px;
      font-size: 16px;
      font-weight: bold;
      // flex: 42%;
      text-align: center;
    }
    .center {
      margin-left: 1px;
    }
    .right {
      // width: 30px;
      height: 36px;
    }
  }
}
</style>