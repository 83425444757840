<!--
 * @Descripttion:
 * @Author: luoyang
 * @Date: 2021-09-24 15:13:16
 * @LastEditors: luoyang
 * @LastEditTime: 2021-10-22 18:00:16
-->
<template>
    <div class="jobs baseBg">
        <div class="popItemBox section official">
            <div class="jobs-title">社会招聘</div>
            <div class="content p20 flex-start-center">
                <div class="item item-basic"
                     v-for="(item,index) in officialData"
                     :key="index">
                    <div class="section-1 flex-between-center">
                        <div class="section-1-left">
                            <div class="post-date">发布时间:{{item.postDate}}</div>
                            <div class="name-city">
                                <span class="name">{{item.name}}</span>
                                <span v-if="item.city"
                                      class="city ml10">/{{item.city}}</span>
                            </div>
                        </div>
                        <div class="section-1-right">
                            <el-switch v-model="item.online"
                                       @change="changeStatus(item.id,item.online)"
                                       active-color="#0091FB"
                                       inactive-color="#E7E7E7">
                            </el-switch>
                        </div>
                    </div>
                    <div class="section-2 flex-start-center">
                        <div class="section-2-salaryMonths">
                            <span v-if="item.salaryLow&&item.salaryHigh">{{item.salaryLow}}-{{item.salaryHigh}}K</span>
                            <span v-else-if="item.salaryLow">{{item.salaryLow}}K</span>
                            <span v-else-if="item.salaryHigh">{{item.salaryHigh}}K</span>
                            <span v-else>面议</span>

                            <span v-if="item.salaryMonths">,{{item.salaryMonths}}薪</span>

                        </div>
                        <div class="section-2-education">
                            <span v-if="item.yearsLow&&item.yearsHigh">{{item.yearsLow}}-{{item.yearsHigh}}年</span>
                            <span v-else-if="item.yearsLow">{{item.yearsLow}}年</span>
                            <span v-else-if="item.yearsHigh">{{item.yearsHigh}}年</span>
                            <span v-else>经验不限</span>
                            <span class="ml10">|</span>
                            <span class="ml10">{{item.education||"学历不限"}}</span>
                        </div>

                    </div>
                    <div class="section-3 flex-between-start">
                        <div class="left">
                            <div class="section-3-item-out-box flex-start-center"
                                 :class="item.websites.length>3?'have-scrollbar':'no-scrollbar'">
                                <span class="section-3-item"
                                      v-for="(item2,index) in item.websites"
                                      :key="index"
                                      @click="goToWeb(item2.websiteUrl)"
                                      :class="item2.websiteName.indexOf('拉勾')!=-1?'lgw':(item2.websiteName.indexOf('智联')!=-1?'zlzp':'bszp')">
                                    {{item2.websiteName}}
                                </span>
                            </div>

                        </div>
                        <div class="flex-between-center right">
                            <i class="el-icon-edit-outline"
                               @click="details(item,'OFFICIAL')"></i>
                            <i class="el-icon-delete"
                               @click="del(item,'OFFICIAL')"></i>
                        </div>

                    </div>
                </div>
                <div class="item add-item"
                     @click="add('OFFICIAL')">
                    <div class="out flex-between-center">
                        <div class="icon-box"><i class="el-icon-plus"></i></div>
                        <div class="add-box">新增招聘岗位</div>
                    </div>

                </div>
            </div>

        </div>
        <div class="popItemBox section internship">
            <div class="jobs-title">实习生招聘（校园招聘）</div>
            <div class="content p20 flex-start-center">
                <div class="item item-basic"
                     v-for="(item,index) in internshipData"
                     :key="index">
                    <div class="section-1 flex-between-center">
                        <div class="section-1-left">
                            <div class="post-date">发布时间:{{item.postDate}}</div>
                            <div class="name-city">
                                <span class="name">{{item.name}}</span>
                                <span v-if="item.city"
                                      class="city ml10">/{{item.city}}</span>
                            </div>
                        </div>
                        <div class="section-1-right">
                            <el-switch v-model="item.online"
                                       @change="changeStatus(item.id,item.online)"
                                       active-color="#0091FB"
                                       inactive-color="#E7E7E7">
                            </el-switch>
                        </div>
                    </div>
                    <div class="section-2 flex-start-center">
                        <div class="section-2-salaryMonths">
                            <span v-if="item.salaryLow&&item.salaryHigh">{{item.salaryLow}}-{{item.salaryHigh}}K</span>
                            <span v-else-if="item.salaryLow">{{item.salaryLow}}K</span>
                            <span v-else-if="item.salaryHigh">{{item.salaryHigh}}K</span>
                            <span v-else>面议</span>

                            <span v-if="item.salaryMonths">,{{item.salaryMonths}}薪</span>

                        </div>
                        <div class="section-2-education">
                            <span v-if="item.yearsLow&&item.yearsHigh">{{item.yearsLow}}-{{item.yearsHigh}}年</span>
                            <span v-else-if="item.yearsLow">{{item.yearsLow}}年</span>
                            <span v-else-if="item.yearsHigh">{{item.yearsHigh}}年</span>
                            <span v-else>经验不限</span>
                            <span class="ml10">|</span>
                            <span class="ml10">{{item.education||"学历不限"}}</span>
                        </div>

                    </div>
                    <div class="section-3 flex-between-start">
                        <div class="left">
                            <div class="section-3-item-out-box flex-start-center"
                                 :class="item.websites.length>3?'have-scrollbar':'no-scrollbar'">
                                <span class="section-3-item"
                                      v-for="(item2,index) in item.websites"
                                      :key="index"
                                      @click="goToWeb(item2.websiteUrl)"
                                      :class="item2.websiteName.indexOf('拉勾')!=-1?'lgw':(item2.websiteName.indexOf('智联')!=-1?'zlzp':'bszp')">
                                    {{item2.websiteName}}
                                </span>
                            </div>

                        </div>
                        <div class="flex-between-center right">
                            <i class="el-icon-edit-outline"
                               @click="details(item,'INTERNSHIP')"></i>
                            <i class="el-icon-delete"
                               @click="del(item,'INTERNSHIP')"></i>
                        </div>

                    </div>
                </div>
                <div class="item add-item"
                     @click="add('INTERNSHIP')">
                    <div class="out flex-between-center">
                        <div class="icon-box"><i class="el-icon-plus"></i></div>
                        <div class="add-box">新增实习岗位</div>
                    </div>

                </div>
            </div>
        </div>
        <!-- 新增弹框 -->
        <popAddJob ref="popAddJob"
                   @update="getList"></popAddJob>
    </div>
</template>

<script>
import API from "@/api/homeConfigManage.js";
import popAddJob from "./components/popAddJob.vue";
export default {
    name: "jobs",
    components: {
        popAddJob,
    },
    data() {
        return {
            searchForm: {
                type: "",
            },
            officialData: "",
            internshipData: "",
        };
    },
    mounted() {
        // 查询列表
        this.getList();
    },
    methods: {
        // 查询列表
        getList() {
            // 正式
            let officialData = Object.assign({}, { type: "OFFICIAL" });
            API.getJobList(officialData)
                .then((res) => {
                    this.officialData = res.content;
                })
                .catch(() => {
                    //   loading.close();
                });
            // 实习
            let internshipData = Object.assign({}, { type: "INTERNSHIP" });
            API.getJobList(internshipData)
                .then((res) => {
                    this.internshipData = res.content;
                })
                .catch(() => {
                    //   loading.close();
                });
        },
        // 上下线官网招聘信息
        changeStatus(id, online) {
            let data = { id, online: online };
            API.onOffJob(data)
                .then(() => {
                    this.$message.success("操作成功");
                    this.getList();
                })
                .catch(() => {
                    this.getList();
                });
        },
        // 删除官网招聘信息
        del(row, type) {
            let des = type === "OFFICIAL" ? "" : "实习";
            this.$confirm(
                `是否删除${des}岗位“${row.name}”，删除后无法恢复？`,
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    API.deleteJob({
                        id: row.id,
                    }).then(() => {
                        this.$message.success("操作成功");
                        this.getList();
                    });
                })
                .catch(() => {});
        },
        // 跳转
        goToWeb(websiteUrl) {
            window.open(websiteUrl, "_blank");
        },
        // 新增
        add(type) {
            // 打开新增弹框
            this.$refs.popAddJob.dialogVisible = true;
            this.$nextTick(function () {
                this.$refs.popAddJob.title =
                    type === "OFFICIAL" ? "新增招聘岗位" : "新增实习岗位";
                this.$refs.popAddJob.form.type = type;
            });
        },
        // 查看详情 编辑
        details(row, type) {
            // 打开新增弹框
            this.$refs.popAddJob.dialogVisible = true;
            this.$nextTick(function () {
                this.$refs.popAddJob.title =
                    type === "OFFICIAL" ? "编辑招聘岗位" : "编辑实习岗位";
                this.$refs.popAddJob.initForm(row.id);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.jobs {
    ::v-deep ::-webkit-scrollbar {
        height: 3px !important;
        background-color: #eef0f3;
    }
    ::v-deep ::-webkit-scrollbar-thumb {
        background-color: #acbbc6;
    }
    .flex-between-start {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    // ::v-deep .el-switch{
    //   height: 28px;
    //   .el-switch__core{
    //     height: 28px;
    //     &::after{
    //       width: 28px;
    //       height: 28px;
    //     }
    //   }
    // }
    .section {
        height: 50%;
        width: 100%;
        background-color: #fff;
        background: #ffffff;
        border-radius: 10px;
        .jobs-title {
            padding-left: 20px;
            width: 100%;
            font-size: 16px;
            line-height: 54px;
            font-weight: bold;
            color: #333333;
            border-bottom: 1px solid #ebeef5;
        }
        .content {
            flex-wrap: wrap;
            padding-bottom: 0;
            .item {
                width: 24%;
                margin-right: 1.333%;
                margin-bottom: 20px;
                min-height: 182px;
                font-size: 12px;
                border: 1px solid #e2e4ea;
                box-sizing: border-box;
                &:nth-child(4n) {
                    margin-right: 0;
                }
            }
            .item-basic {
                padding: 16px 15px;
                line-height: 20px;
                .section-1 {
                    .section-1-left {
                        .post-date {
                            color: #b6b7b8;
                        }
                        .name-city {
                            margin-top: 13px;
                            .name {
                                color: #101010;
                                font-size: 18px;
                                font-weight: bold;
                            }
                            .city {
                                color: #101010;
                            }
                        }
                    }
                }
                .section-2 {
                    margin-top: 22px;
                    .section-2-salaryMonths {
                        flex: 1;
                        color: #ff0000;
                        font-weight: bold;
                    }
                    .section-2-education {
                        flex: 1;
                        color: #101010;
                    }
                }
                .section-3 {
                    // margin-top: 14px;
                    .left {
                        width: 288px;
                        overflow: auto;

                        .section-3-item-out-box {
                            min-width: 288px;
                            flex-wrap: nowrap;
                            margin-bottom: 6px;
                            &.no-scrollbar {
                                margin-bottom: 12px;
                            }
                        }
                    }
                    .section-3-item {
                        margin-top: 14px;
                        display: inline-block;
                        flex: 0 0 86px;
                        line-height: 28px;
                        height: 28px;
                        background: #00a5a0;
                        border-radius: 14px;
                        text-align: center;
                        color: #ffffff;
                        background-color: #00a5a0;
                        cursor: pointer;
                        margin-right: 10px;
                        &.lgw {
                            background: #50b08c;
                        }

                        &.zlzp {
                            background: #1228f1;
                        }
                    }
                    .right {
                        height: 100%;
                        margin-top: 20px;
                        width: 60px;
                        font-size: 20px;
                        .el-icon-edit-outline,
                        .el-icon-delete {
                            cursor: pointer;
                        }
                    }
                }
            }
            .add-item {
                display: grid;
                place-items: center;
                text-align: center;
                border: 1px dashed rgba(187, 187, 187, 100);
                cursor: pointer;
                .out {
                    flex-direction: column;
                    .icon-box {
                        width: 60px;
                        height: 60px;
                        background: #008ffb;
                        border-radius: 50%;
                        display: grid;
                        place-content: center;
                        .el-icon-plus {
                            color: #fff;
                            margin-bottom: 6px;
                            font-size: 28px;
                        }
                    }
                    .add-box {
                        font-size: 20px;
                        color: #010101;
                        margin-top: 24px;
                    }
                }
            }
        }
    }
}
</style>