/*
 * @Descripttion: 
 * @Author: luoyang
 * @Date: 2021-09-22 16:11:35
 * @LastEditors: luoyang
 * @LastEditTime: 2021-09-26 16:22:50
 */
// 官网配置  homeConfigManage
// import request from '@/utils/request'
import {
  get,
  // post,
  postForm,
  postJson,
} from "./http/http";

const httpServer = {
  // 根据条件查询官网信息列表
  getList(params) {
    return postJson(`/official/website-information/table/1`, params);
  },
  // 官网配置 列表各状态计数
  implementationManageCount(params) {
    return get("/task/task/countByStage", params);
  },
  // 保存官网信息
  save(params) {
    return postJson(`/official/website-information/save`, params);
  },
  // 发布或者取消发布官网信息
  publish(params) {
    return postJson(`/official/website-information/publish`, params);
  },
  // 上下移官网信息
  moveUpOrDown(params) {
    return postForm(`/official/website-information/moveUpOrDown`, params);
  },
  // 置顶官网信息
  stick(params) {
    return postForm(`/official/website-information/stick`, params);
  },
  // 获取官网信息详情
  detail(params) {
    return get("/official/website-information/detail", params);
  },
  // 删除官网信息
  delete(params) {
    return postForm(`/official/website-information/delete`, params);
  },
  // 批量删除官网信息
  batchDelete(params) {
    return postJson(`/official/website-information/batch/delete`, params);
  },
  // 获取官网招聘信息列表
  getJobList(params) {
    return postForm(`/official/recruitment-information/list`, params);
  },
  // 获取官网招聘信息列表(官网)
  getJobWebList(params) {
    return postForm(`/official/recruitment-information/web/list`, params);
  },
  // 保存官网招聘信息
  saveJob(params) {
    return postJson(`/official/recruitment-information/save`, params);
  },
  // 上下线官网招聘信息
  onOffJob(params) {
    return postJson(`/official/recruitment-information/onOff`, params);
  },
  // 获取官网招聘信息详情
  detailJob(params) {
    return get("/official/recruitment-information/detail", params);
  },
  // 删除官网招聘信息
  deleteJob(params) {
    return postForm(`/official/recruitment-information/delete`, params);
  },
};

export default httpServer;
